#graph-column {
  padding: 5px;
  min-height: 35vh;
}

.cursor-pointer {
  cursor: pointer
}

#graph-column > div {
  display: inline;
  position: absolute;
  padding: 0 !important;
  height: 0 !important;
  width: 0 !important;
}

#graph-column > div > div {
  display:inline-block;
}

.mv-remove-colours {
  background-color: #0d6efd;
  transition: background-color 0.2s ease
}

.mv-remove-colours:hover {
  background-color: #dc3545 !important
}

.bestiaryNoOutline {
  margin: 1px
}

img#UserJourney {
  width: 80%
}

/*#graph-column canvas {*/
/*  border: 1px solid rgba(255, 255, 255, 0.15);*/
/*  border-radius: 1em*/
/*}*/

div#root {
  height:100vh;
  width:100vw;
}

div#borderRow {
  height: calc(100% - 2px)
}

body {
  overflow-x: hidden
}

::selection {
  background: #0d6efd;
}

::-moz-selection {
  background: #0d6efd;
}

 /*Medium devices (tablets, 768px and up)*/

div.mobileOnly {
  display: none !important;
}

@media (max-width: 768px) {

  div.responsive-fill-block {
    min-height: 100px;
    max-height: 400px;
    position: relative !important;
    overflow-y: auto;
  }
  div.responsive-fill-block.suggestion {
    max-height: 600px
  }
  nav#bestiaryPagination {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  div#graph-column {
    max-height: 100vw !important;
    min-height: 80vw !important;
    margin-top: .5rem
  }
  div.encounterButtons {
    margin-bottom: .5rem;
  }
  div.mobileOnly {
    display: block !important
  }
  div.mobileHide {
    display: none !important
  }

}